import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    base_url: "http://127.0.0.1:8000/",
    token: localStorage.getItem("token") || null,
    user_name: localStorage.getItem("user_name") || null,
    role_name: localStorage.getItem("role_name") || null,

    getAppointmentUsers: [],
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
    getAppointmentUsers(state) {
      return state.getAppointmentUsers;
    },
  },
  mutations: {
    setToken(state, data) {
      state.token = data.token;
      state.user_name = data.name;
      state.role_name = data.role_name;
    },
    removeToken(state) {
      state.token = null;
      state.user_name = null;
      state.role_name = null;
    },

    getAppointmentUsers(state, data) {
      return (state.getAppointmentUsers = data);
    },
  },
  actions: {
    removeToken(context) {
      context.commit("removeToken");
    },
    setToken(context, data) {
      context.commit("setToken", data);
    },

    CheckAuth(content) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/check")
        .then((res) => {
          if (!res.data.status) {
            localStorage.removeItem("token");
            content.commit("removeToken");
            router.push("/login");
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem("token");
            content.commit("removeToken");
            router.push("/login");
          }
        });
    },

    getAppointmentUsers(content) {
      axios
        .post("/get/appointment/users")
        .then((res) => {
          content.commit("getAppointmentUsers", res.data.users);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
});
